import React from 'react';
import { graphql } from 'gatsby';
import Head from '../components/head';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

const Page = ({ 
    location, 
    data: { 
        markdownRemark: { 
            html, 
            frontmatter: { 
                description, title 
            }
        }
    }
}) => {
    return (
        <>
            <Head 
                description={description} 
                pageTitle={title} 
                location={location} 
            />
            <Breadcrumb 
                location={location} 
                crumbLabel={title}
            />
            <main className="slow-fade" dangerouslySetInnerHTML={{ __html: html }}/>
        </>
    );
}


export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html 
            frontmatter {
                title
                description
            }
        }
    }
`;

export default Page;